
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import FlexibleContent from '@/templates/flexible-content';
import { getLayout } from '@/layout';
import config from '@/config';
import i18n from '@/i18n';
import { getAllRoutes, getCommonData } from '@/graphql/api';
import { client } from '@/graphql/client';
import { getEdificio, getPage } from '@/graphql/queries';
import { buildingTransformer } from '@/graphql/transformer/buildingTransformer';
import { errorGrahpQL, getWordpressPath } from '@/graphql/utils';
import { sectionTransformer } from '@/lib/graphql/transformer/sections';
import type { TypeFlexibleContent } from '@/lib/graphql/transformer/sections';
import type { GetStaticProps } from 'next';
import type { AppCommonProps } from './_app';
type PageProps = {
    flexibleContent: TypeFlexibleContent[];
};
export const Page = ({ flexibleContent }: PageProps) => (<FlexibleContent flexibleContent={flexibleContent}/>);
const getStaticProps: GetStaticProps<PageProps & AppCommonProps> = async ({ locale, params }) => {
    const { uri } = params || {};
    const page = await client.query({
        query: getPage,
        variables: {
            slug: getWordpressPath({
                slug: uri,
                locale: locale ?? i18n.defaultLocale,
            }),
        },
    });
    const data = page?.data?.page;
    errorGrahpQL(page);
    if (!data || !data.language.locale.includes(locale))
        return { notFound: true };
    const { contenidoFlexible: { contenido: flexibleContentData = [] }, seo, } = data;
    const edificioId = data?.contenido?.edificio?.id;
    const databaseId = data?.contenido?.edificio?.databaseId;
    const province = data?.contenido?.province;
    let edificio = null;
    if (edificioId) {
        const tempEdificio = await client.query({
            query: getEdificio,
            variables: { id: edificioId },
        });
        if (tempEdificio.data)
            edificio = await buildingTransformer(tempEdificio.data?.edificio?.edificio, locale);
        else
            throw new Error(`No se ha encontrado el edificio con id: ${edificioId}`);
    }
    const flexibleContent = await sectionTransformer({
        flexibleContent: flexibleContentData,
        building: edificio,
        locale: locale ?? i18n.defaultLocale,
        buildingId: databaseId ?? null,
        landing: data?.contenido.landing,
        province: province,
    });
    const { footer, header } = await getCommonData({
        locale: locale ?? i18n.defaultLocale,
        content: data.contenido,
        landing: data?.contenido.landing,
        sections: flexibleContentData,
        translations: data.translations,
    });
    return {
        props: {
            seo,
            flexibleContent,
            header,
            footer,
        },
        revalidate: config.revalidate,
        notFound: !data,
    };
};
// TODO: Esto es un hotfix para hacer funcionar la compilación
// y no haya colisión de rutas. La solución correcta sería
// generar un contenido flexible que muestre todas las localizaciones
// ya que la ruta /donde-estamos es en verdad una pagina y debería capturarse
// aquí
export const getStaticPaths = async () => ({
    paths: (await getAllRoutes({ type: 'pages' })).filter((route) => !(route.params.slug === 'donde-estamos') &&
        !(route.params.slug === 'non-gaude') &&
        !(route.params.slug === 'on-som') &&
        !(route.params.slug === 'where-are-we')),
    fallback: 'blocking',
});
Page.getLayout = getLayout;
export default Page;

    async function __Next_Translate__getStaticProps__19271a6d5a8__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/[[...uri]]',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19271a6d5a8__ as getStaticProps }
  